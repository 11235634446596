/**************************************************************************/
/* Include global CSS variables in this file.                             */
/*                                                                        */
/* Because this file is included in the top level "App" component,        */
/* all CSS vars in this file will be available in all the components.     */
/**************************************************************************/

:root {
    --navbar-height: 41px;
    --distance-text-color: lime;
}