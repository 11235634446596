.coordinateInfobox {
  position: absolute;
  height: 59px;
  width: 125px;
  bottom: 10px;
  left: 10px;
  background: rgba(255, 255, 255);
  opacity: 70%;

  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 2px 2px 2px 2px;

  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.38);
}

.note {
  font-size: 8px;
  margin-top: 10px;
  margin-left: 2px;
}

.coordinateLabel {
  font-family: "Roboto Mono", monospace;
  font-size: 13px;
  display: inline-block;
  width: 30px;
  text-align: justify;
  margin-left: 2px;
  /*border: 1px solid black;*/
}

.coordinateNumber {
  font-family: "Roboto Mono", monospace;
  font-size: 13px;
  display: inline-block;
  width: 85px;
  text-align: right;
  /*margin-left: 5px;*/
  /*border: 1px solid black;*/
}
