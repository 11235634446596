/**

See: https://uicolors.app/create
A potential pallet of Green colors

'killarney': {
    '50': '#f4f9f5',
    '100': '#e5f3e9',
    '200': '#cce6d3',
    '300': '#a3d2b1',
    '400': '#73b587',
    '500': '#509765',
    '600': '#3d7c50',
    '700': '#356644',
    '800': '#2c4f37',
    '900': '#26412f',
    '950': '#102317',
},
 */

.topNav {
  background-color: #f1fafd;
  font-family:
    Inter,
    ui-sans-serif,
    system-ui,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
  font-size: 16px;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
}

.topNavContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 40px;
  align-content: center;
  align-items: center;
}

.topNavLogo {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-optical-sizing: auto;
  text-align: start;
  opacity: 1;
  line-height: initial;
  margin-left: 20px;
}

.topNavLogo a {
  all: unset;
}

.topNavLogo a:hover {
  cursor: pointer;
}

.topNavMenuItems a {
  all: unset;
}

.topNavMenuItems span {
  margin-right: 20px;
}
.topNavMenuItems span:hover {
  color: #1d4ed8;
  cursor: pointer;
}

/* Basic styling for the dropdown button */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Container for the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Styling for the dropdown content */
.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 1px;
}

/* Styling for dropdown content links */
.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Highlight the dropdown content links on hover */
.dropdownContent a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content when the button is hovered over */
.dropdown:hover .dropdownContent {
  display: block;
}

