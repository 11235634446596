#body {
    /*background-color: #5CDB95;*/
}

.homePage {
    /*background-color: #5CDB95;*/
}

.hero {
    margin-top: 50px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-evenly;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    font-weight: 600;
    color: #05386B;
}

.textIconColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tagLine {
    width: 100%;
    font-size: 36px;
    font-weight: 100;
    text-align: center;
    letter-spacing: 0.025em;
    margin-bottom: 40px;
}

.subText {
    width: 100%;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.025em;
    margin-bottom: 70px;
}

.callToAction {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    color: white;
}

.callToActionLink {
    width: 300px;
    padding: 10px;
    /*border: 1px solid rgba(203, 213, 224, 1);*/
    /*background-color: rgba(66,153,225, 1);*/
    background-color: #05386B;
    align-content: center;
    color: white;
    font-weight: 700;
    border-radius: 0.25rem;
    text-decoration: none;
}

.callToActionLink a {
    text-decoration: none;
    color: white;
}
.callToActionLink:hover {
    cursor: pointer;
}


.textColor {
    color: #05386B;
    letter-spacing: 0.025em;
}

.iconContainer {
    display: flex;
    justify-content: space-evenly;
}

.icons {
    margin: 10px 10px;
    font-size: 64px;
}

.iconHiking {
    font-size: 56px;
    padding-top: 8px;
}