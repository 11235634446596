.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.map {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-route-builder {
  position: absolute;
  top: 20px;
  right: 10px;
}
