.unitsChooser {
    position: relative;
}

.unitsChooser select {
    /* Reset Select */
    appearance: none;
    outline: 10px red;
    border: 1px black solid;
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.38);
    flex: 1;
    padding: 0 3px;
    color: #00ff00;
    background-color: rgb(30, 29, 29);
    background-image: none;
    cursor: pointer;
    text-align: center;
}

.unitsChooser option {

}