.mapButton {
  position: relative;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background-color: #ffffff;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.mapButton svg {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.mapButton:active {
  outline: none;
  box-shadow: 0 0 0 3px #2563eb;
}

.mapButton:hover {
  background-color: #e5e5e5;
}

.left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.middle {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
