.distanceInfoBox {
    position: absolute;
    height: 30px;
    width: 240px;
    top: 20px;
    left: 10px;
    background: rgba(30, 29, 29);
    opacity: 70%;
    /* top-left | top-right | bottom-right | bottom-left */
    border-radius: 2px 2px 2px 2px;

    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.38);

    color: #00ff00;
}

.distanceContainer {
    display: flex;
    align-items: center;
    margin: 5px;
}

.distanceLabel {
    margin: 0 5px;
}

.distanceValue {
    color: var(--distance-text-color);
    margin-right: 25px;
    width: 100px;
}

